<template>
  <div class="initiate-view">
    <tojoy-title-content title="我发起的">
      <template #footer>
        <div class="tojoy-title-content__footer">
          <tojoy-tabs v-model="tabName" :tabs="tabs" @tab-click="handleTabClick" />
        </div>
      </template>
    </tojoy-title-content>
    <tojoy-pagination-list
      :data="listData"
      ref="tojoy-pagination-list-ref"
      @page-change="handleChangePage"
      schema="block"
      :total="total"
      :loading="loading"
    >
      <template #item="{data}">
        <card :data="data" modelCode="team" :moduleName="moduleName">
          <template #num>
            <span class="num">{{ data.finalScore | formateFinalScore(data.processState) }}</span>
          </template>
          <template #status>
            <span class="status" :class="`status-${data.processState}`">{{
              data.processName | formateProcessState(data.approverName, data.processState)
            }}</span>
          </template>
        </card>
      </template>
    </tojoy-pagination-list>
  </div>
</template>

<script>
import TojoyTitleContent from '@/components/common/initiate-team/title-content'
import TojoyPaginationList from '@/components/business/pagination-list'
import card from '@/components/initiate/card'
import tabsRouteMixins from '@/mixins/tabsRouteMixins'
import listMixin from '@/mixins/initiate/listMixin.js'
import { getMeSponsor, getListCount } from '@/api/api_team'
import {
  getMeSponsor as getMeSponsorCustomer,
  getListCount as getListCountCustomer
} from '@/api/api_customer'
import TojoyTabs from '@/components/business/tabs'
export default {
  name: 'initiate',
  mixins: [listMixin, tabsRouteMixins],
  components: {
    card,
    TojoyTitleContent,
    TojoyPaginationList,
    TojoyTabs
  },
  props: {
    moduleName: {
      type: String,
      default: 'all'
    }
  },
  data() {
    return {}
  },
  created() {
    this.getCount()
    let obj = {
      all: 0,
      submit: 1,
      approval: 2,
      through: 3
    }
    this.tabIndex = obj[this.moduleName]
    this.loading = true
    this.getList()
  },
  methods: {
    async handleApi(type = 'list', state) {
      //list 列表  count 列表条数接口
      try {
        if (sessionStorage.getItem('code') === 'contribute') {
          if (type === 'count') {
            let params = {
              contributeState: 0
            }
            return await getListCount(params)
          } else {
            let params = {
              page: this.page,
              size: this.size,
              contributeState: this.tabIndex
            }
            return await getMeSponsor(params)
          }
        } else {
          if (type === 'count') {
            let params = {
              customState: 0,
              dimensionCode: sessionStorage.getItem('code')
            }
            return await getListCountCustomer(params)
          } else {
            let params = {
              page: this.page,
              size: this.size,
              customState: this.tabIndex,
              dimensionCode: sessionStorage.getItem('code')
            }
            return await getMeSponsorCustomer(params)
          }
        }
      } catch (err) {
        return err
      }
    },
    getCount() {
      this.handleApi('count')
        .then(res => {
          // 待审批 approvalPending
          let { toBeSubmitted: submit, underReview: approval } = res.data
          this.$set(this.tabs[1], 'badge', submit)
          this.$set(this.tabs[2], 'badge', approval)
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleChangePage(val) {
      this.page = val
      this.loading = true
      this.getList()
    },
    getList() {
      this.handleApi()
        .then(res => {
          let { list, total } = res.data
          this.listData = list
          this.listData.forEach((el, index) => {
            if (sessionStorage.getItem('code') === 'contribute') {
              this.$set(el, 'companyName', el.implementOrgName || '')
            }
          })
          this.total = total
          this.loading = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleTabClick({ index }) {
      this.tabIndex = index
      this.listData = []
      this.total = 0
      this.page = 1
      this.size = 10
      this.loading = true
      this.$refs['tojoy-pagination-list-ref'].resetScrollTop()
      this.getList(index)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/approval.scss';
</style>
