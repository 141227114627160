import { initiateTabNames } from '@/components/initiate/state.js'
export default {
  data() {
    return {
      loading: false,
      total: 0,
      page: 1,
      size: 10,
      listData: [],
      tabs: initiateTabNames,
      tabIndex: '0',
      tabName: this.moduleName
    }
  },
  filters: {
    formateProcessState(name, val, code) {
      //审批中-xx 已驳回-xx
      //提交 1 已撤销 2  待审批 3
      //审批中 4 驳回 5  审批通过 6
      //问题 ？？？待审批没对应颜色
      if ([3, 4, 5].includes(code)) {
        return `${name}-${val}`
      } else {
        return `${name}`
      }
    },
    formateFinalScore(val = 0, code) {
      //审批通过
      if (code === 6) {
        return `奖励${val || 0}分`
      } else {
        return ''
      }
    }
  },
  mounted() {},
  methods: {}
}
