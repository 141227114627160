<template>
  <div class="card" @click="toPage(data)">
    <p class="card-title">{{ data.title || '' }}</p>
    <p class="card-name">
      <span class="item">{{ data.companyName || '' }}</span>
      <slot name="num"></slot>
    </p>
    <p class="card-time">
      <span class="item">{{ data.createTime || '' }}</span>
      <slot name="status"></slot>
    </p>
  </div>
</template>

<script>
export default {
  name: 'card',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: 'initiate' //发起 initiate 审批 approval
    },
    modelCode: {
      type: String,
      default: 'innovate' //innovate 创新成果 team 团队贡献
    },
    moduleName: {
      type: String,
      default: 'all'
    }
  },
  methods: {
    toPage(data) {
      let path = ''
      if (this.modelCode === 'innovate') {
        path = this.type === 'initiate' ? 'initiateDetail' : 'approvalDetail'
      } else {
        if (sessionStorage.getItem('code') === 'contribute') {
          path = this.type === 'initiate' ? 'team-initiateDetail' : 'team-approvalDetail'
        } else {
          if (sessionStorage.getItem('customize1')) {
            path = this.type === 'initiate' ? 'customer-initiateDetail' : 'customer-approvalDetail'
          } else {
            path =
              this.type === 'initiate' ? 'customer2-initiateDetail' : 'customer2-approvalDetail'
          }
        }
      }
      //customCode
      let id =
        this.modelCode === 'innovate'
          ? data.innovateCode
          : sessionStorage.getItem('code') === 'contribute'
          ? data.contributeCode
          : data.customCode
      this.$router.push({
        name: path,
        query: {
          id,
          type: this.type,
          modelCode: this.modelCode,
          moduleName: this.moduleName
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  border-radius: 5px;
  opacity: 0.99;
  padding-top: 5px;
  padding-bottom: 20px;
  cursor: pointer;
  flex: 1;
  &-title {
    font-size: 16px;
    font-weight: 500;
    color: $lightblack;
  }
  &-name {
    font-size: 12px;
    font-weight: 400;
    color: $darkgrey;
    line-height: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    .item {
      flex: 1;
    }
  }
  &-time {
    font-size: 12px;
    font-weight: 400;
    color: $grey;
    line-height: 12px;
    display: flex;
    .item {
      flex: 1;
    }
  }
}
</style>
